import React, { useEffect, useState } from 'react';
import './NavBar.css';
import { Link, useHistory } from 'react-router-dom';

const NavBar = () => {
  const history = useHistory();
  const [isNavigating, setIsNavigating] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleHomeNavigation = () => {
    setIsNavigating(true);

    setTimeout(() => {
      history.push('/');
    }, 500);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  if (windowWidth > 624) {
    return (
      <div className={`navbar ${isNavigating ? 'exiting' : null}`}>
        <button className="navbar-link" id="logo-button" onClick={() => handleHomeNavigation()} type="button">
          C&#183;M
        </button>
        <Link className="navbar-link" to="/about">
          ABOUT
        </Link>
        <Link className="navbar-link" to="/projects">
          PROJECTS
        </Link>
        <Link className="navbar-link" to="/travel">
          TRAVEL
        </Link>
        <Link className="navbar-link" to="/brewing">
          BREWING
        </Link>
        <Link className="navbar-link" to="/contact">
          CONTACT
        </Link>
      </div>
    );
  }
  return (
    <div className={`navbar ${isNavigating ? 'exiting' : null}`}>
      <button className="navbar-link" id="logo-button" onClick={() => handleHomeNavigation()} type="button">
        C&#183;M
      </button>
    </div>
  );
};

export default NavBar;
