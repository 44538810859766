import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import IMAGES from '../res/images';
import NavBar from '../components/widgets/NavBar/NavBar';
import About from '../components/pages/About/About';
import Brewing from '../components/pages/Brewing/Brewing';
import Contact from '../components/pages/Contact/Contact';
import Home from '../components/pages/Home/Home';
import Projects from '../components/pages/Projects/Projects';
import Travel from '../components/pages/Travel/Travel';
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <div className="app-content">
        <img className="home-bg-topo" alt="bg" src={IMAGES.BG_TOPO} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route>
            <NavBar />
            <Route path="/about">
              <About />
            </Route>
            <Route path="/brewing">
              <Brewing />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/travel">
              <Travel />
            </Route>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
