import React, { useState } from 'react';
import './Home.css';
import { Link, useHistory } from 'react-router-dom';

const Home = () => {
  const history = useHistory();
  const [isNavigating, setIsNavigating] = useState(false);

  const handleNavigation = (destination) => {
    setIsNavigating(true);

    setTimeout(() => {
      history.push(destination);
    }, 500);
  };

  return (
    <div className={`home-page-content ${isNavigating ? 'exiting' : null}`}>
      <Link className="home-link" to="/">
        C H R I S &#183; M A S O N
      </Link>
      <div className="navigation link">COMING SOON</div>
    </div>
  );
};

export default Home;
