import React from 'react';
import IMAGES from '../../../res/images';
import './About.css';

const About = () => {
  return (
    <div className="about-page-content">
      <img className="about-me-hero" alt="hero" src={IMAGES.OBSERVATION_POINT} />
    </div>
  );
};

export default About;
